<template>
    <div v-if="state.game" class="screenGameWaiting">
        <h1>{{ state.game.title }}</h1>
        <p>Game code: {{ String(state.game.session).toUpperCase() }}</p>
        <VueQrcode :value="hotlink"></VueQrcode>
        <div v-if="state.game.phase === 'waiting'">
            <p v-if="state.game.playersMissing > 0">Waiting for {{ state.game.playersMissing }} players</p>
            <p v-if="state.game.playersMissing === 0 && state.game.master !== state.user.uid">
                Waiting for game master to start the game
            </p>
            <div id="waitingRoomStart" class="d-grid gap-2"
                 v-if="state.game.playersMissing === 0 && state.game.master === state.user.uid">
                <button class="btn btn-primary btn-block" @click="startGame()">Start Game</button>
            </div>
        </div>
        <div v-if="state.game.phase === 'playing'">
            <h1>Game is starting!</h1>
        </div>
        <div id="waitingRoomSlots">
            <div class="avatar-waiting" v-for="player in state.players" :key="player.uid">
                <img class="avatar"
                     :class="{ avatarOffline: !player.online }"
                     :src="player.avatar"/>
                <div class="avatar-waiting-name" style="overflow:hidden">
                    {{ player.name }}
                </div>
                <div v-if="state.game.master === state.user.uid" class="avatar-waiting-role">
                    {{ player.role.name }}
                </div>
                <div class="avatar-waiting-online">
                    {{ onlineWording(player) }}
                </div>
            </div>
            <div class="avatar-waiting" v-for="player in state.game.playersMissing" :key="player.uid">
                <div class="avatar-waiting-question-mark">?</div>
                <div class="avatar-waiting-name" style="overflow:hidden">
                    waiting for another player
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {state} from '@/engine/state.js'
import {Message} from '@/engine/message'
import VueQrcode from '@chenfengyuan/vue-qrcode';


export default {
    name: 'ScreenGameWaiting',
    components: {
        VueQrcode
    },
    data() {
        return {
            state,
            hotlink : window.location.origin + '?hotlink=' + state.game.session
        }
    },
    methods: {
        onlineWording(player) {
            if (player.online) return 'Online';
            const offlineSeconds = state.time.server - player.offlineSince;
            const kickedOutIn = state.game.offlineTimeout - offlineSeconds;
            if (kickedOutIn > 0) return 'Time out: ' + Math.round(kickedOutIn) + 's';
            else return 'GONE';
        },
        startGame() {
            const message = new Message('start', 'game');
            message.send();
        }
    },
}

</script>

<style scoped>

div.screenGameWaiting {
    flex: 1;
}

div#waitingRoomStart {
    padding-left: 10px;
    padding-right: 10px;
}

div#waitingRoomSlots {
    text-align: center;
    width: 100%;

}

div.avatar-waiting {
    width: 120px;
    height: 140px;
    margin: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    float: left;
    background-color: grey;
    border-radius: 5px;
}

div.avatar-waiting img {
    width: 100%;
    height: 70px;
}

div.avatar-waiting-question-mark {
    font-size: 60px;
}

div.avatar-waiting-name {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2rem;
    overflow: hidden;
}

div.avatar-waiting-role,
div.avatar-waiting-online {
    padding: 0;
    font-size: 0.9rem;
    overflow: hidden;
}

div.avatar-waiting-role {
    height: 20px;
    background-color: #444b54;
}

div.avatar-waiting-online {
    height: 20px;
    background-color: #444b54;
}


img.avatar-offline {
    filter: grayscale(1);
}
</style>


