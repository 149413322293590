/**
 * Time library
 */

import moment from 'moment';
import config from '@/config.js'
import log from '@/engine/log.js';
import { Message } from '@/engine/message'

// TODO: activate this for testing
const randomDelta = 0; // Math.round(Math.random() * 100000) / 1000 ;

let diffToServer = 0;

const init = () => {
    if(!synced) sync();
}

const stamp = () => {
    return parseFloat(moment().format('X.SSS')) + randomDelta;
}

const server = () => {
    return parseFloat(moment().format('X.SSS')) + randomDelta + diffToServer;
}

const diff = () => {
    return diffToServer;
}

const format = (seconds) => {
    const negative = seconds < 0;
    if (negative) {
        seconds *= -1;
    }
    return (negative ? '-': '') + moment.utc(moment.duration(seconds * 1000).asMilliseconds()).format("HH:mm:ss");
}

let synced = false;
let syncTimer = null;
const pings = [];
let pingsCount = 0;
let syncSteps = 0;

window.pings = pings;

const sync = () => {
    pingsCount = 0;
    syncSteps = config.time.syncSteps;
    ping();
}

const ping = () => {
    pingsCount++;
    // console.log("ping #" + pingsCount + " of " + syncSteps);
    const message = new Message('get', 'time', stamp());
    message.callback(pingResponse);
    message.send();
    if (syncTimer) clearInterval(syncTimer);
    if (pingsCount < syncSteps) {
        syncTimer = setInterval(
            () => ping(),
            config.time.syncInterval * Math.pow(1.05, pingsCount)
        );
    }
}


const pingResponse = (timeStamps) => {
    // console.log("pingresponse: " , timeStamps);
    const timeSent = timeStamps.client;
    const timeServer = timeStamps.server;
    const timeReceived = stamp();
    const roundTrip = timeReceived - timeSent;
    if (!roundTrip || roundTrip < 0) log.error('illegal round trip result: ' + roundTrip);
    const diff = timeServer - timeSent + (roundTrip / 2);
    pings.push({'diff': diff, 'roundTrip': roundTrip});
    pings.sort(function (a, b) { return Math.sign(a.roundTrip - b.roundTrip); });
    if (pings.length > config.time.syncSteps) pings.pop();
    diffToServer = pings[0].diff;
    const v = calcRoundTripVariance(pings);
    if (syncSteps - pingsCount < 2 && (pings[0].roundTrip >= 0.5 || v > 0.0001)) syncSteps++;
}

const calcRoundTripVariance = (pings) => {
    let sum = 0;
    for (let i in pings) {
        sum += pings[i].roundTrip;
    }
    let mean = sum / pings.length;
    let v = 0;
    for (let i in pings) {
        v += Math.pow(pings[i].roundTrip - mean, 2);
    }
    return v / pings.length;
}

export default {
    init, sync, stamp, server, diff, format
}





/*

    // ping sent the local time, we received the server time
    static pingResponse(sent, received) {
        const t = Time.stamp();
        const roundTrip = t - sent;
        if (!roundTrip || roundTrip < 0) Log.log('Time', Log.ERROR, 'illegal roundTrip value: ' + roundTrip);
        const diff = received - sent + (roundTrip / 2);

        //console.log('pingResponse: ' + sent + ' -> ' + received + ' diff:'+diff);
        Time.pings.push({'diff': diff, 'roundTrip': roundTrip});
        // calculate average diff
        Time.pings.sort(function (a, b) {
            return (a.roundTrip > b.roundTrip) ? 1 : ((b.roundTrip > a.roundTrip) ? -1 : 0);
        });
        if (Time.pings.length > config.time.syncSteps) Time.pings.pop();
        Time.diff = Time.pings[0].diff;
        const sd = Time.roundTripStandardDeviation();
        // need more sync steps?
        if (Time.syncSteps < 2 && (Time.pings[0].roundTrip >= 0.5 || sd > 0.0001)) Time.syncSteps++;
        // update ui
        Ui.data.timeRoundTripStandardDeviation = sd;
        Ui.data.timePings = Time.pings;
    }

    static roundTripStandardDeviation() {
        if (!Time.pings.length) return null;
        let sum = 0;
        for (let i in Time.pings) {
            if(Time.pings.hasOwnProperty(i))
                sum += Time.pings[i].roundTrip;
        }
        let mean = sum / Time.pings.length;
        let sd = 0;
        for (let i in Time.pings) {
            if(Time.pings.hasOwnProperty(i)) sd += Math.pow(Time.pings[i].roundTrip - mean, 2);
        }
        sd /= Time.pings.length;
        return sd;
    }

    static set diff(n) {
        Time.__diff = n;
        Ui.data.timeDiff = n;
    }

    static get diff() {
        return Time.__diff;
    }
}

// for testing robustness of sync: set internal time off by randomDelta
Time.randomDelta = Ui.data.timeRandomDelta = 0; // Math.round(Math.random() * 100000) / 1000 ;
Time.synced = false;
Time.syncTimer = null;
Time.syncSteps = 0;
Time.pings = [];
Time.pingsCount = 0;
Time.diff = 0;*/
