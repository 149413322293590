/**
 * Connection to server via socket.io
 */

import time from "@/engine/time";
import {Message} from "@/engine/message";
import {updateState, updateSubState, state} from '@/engine/state.js'
const {io} = require("socket.io-client");
import log from "@/engine/log";

// TODO: move to centralized config file
const config = {
    server: {
        url: window.location.hostname + (window.location.hostname === 'bamory.com' ? '' : ':8080')
    }
}

const pipeline = [];
let lastReceived = 0;
const updateLastReceived = () => { lastReceived = time.stamp();}
console.log("unused - why? com.lastReceived: ", lastReceived);

// log.info("trying to connect to " + config.server.url + "..");
const socket = io.connect(config.server.url, {secure: true, transports: ['websocket']});


socket.reconnect = () => {
    socket.disconnect();
    socket.connect();
}
window.bam.socket = socket;

socket.on('connect', () => {
    log.verbose('COM', "connect!");
    time.sync();
    getStateFromServer();
});

socket.on('disconnect', () => {
    updateLastReceived();
    updateSubState('com',{'connected': false});
});

socket.on('reconnect', () => {
    updateLastReceived();
    getStateFromServer();
});

socket.on('pong', () => {
    console.log('PONG');
    updateLastReceived();
});

socket.on('ping', () => {
    console.log('PING');
    updateLastReceived();
});


socket.on('message', (m) => {
    log.verbose('COM', "MESSAGE: ", m);
    updateLastReceived();
    handleIncomingMessage(m)
});


const queue = (message) => {
    // console.log("queued message" , message);
    pipeline.push(message);
    send();
}

const getStateFromServer = () => {
    new Message("get", "state", state.user.uid, Message.SEND_AND_WAIT)
        .callback(receivedState)
        .send();
}

const receivedState = (stateUpdate) => {
    // console.log("receivedState:", stateUpdate);
    updateSubState('com',{'connected': true});
    updateSubState('user', stateUpdate.user);
    updateState({'game': stateUpdate.game});
}



// check queue and send all ready-to-send messages
const send = () => {
    let wait = false; // halt sending queued messages until response received?
    for (let i in pipeline) {
        let message = pipeline[i];
        if (message.status === Message.WAIT_AND_HALT) wait = true;
        let send = (message.status === Message.SEND_INSTANTLY
            || ((message.status === Message.SEND_AND_WAIT || message.status === Message.SEND) && !wait));
        if (send) {
            let json = message.json();
            socket.emit('msg', json);
            message.status = Message.status === Message.SEND_AND_WAIT ? Message.WAIT_AND_HALT : Message.WAIT;
        }
    }
}

// incoming message
const handleIncomingMessage = (m) => {
    if (m.res) {
        // find message matching this reply and mark as received
        let found = false;
        for (let i in pipeline) {
            i = Number(i);
            let mp = pipeline[i];
            if (mp.mid === m.mid) {
                // mp.res = JSON.parse(m.res);
                mp.res = m.res;
                mp.stamps = m.stamps;
                mp.status = Message.FINISHED;
                mp.parseResponse();
                found = true;
            }
            if (found) break;
        }
        // TODO: should be handled by custom logger! -> server
        if (!found) throw Error('com' /*, Log.ERROR */, 'mid of received message not found in pipeline');
    } else Message.fromReceived(m).parseIncoming();
    // shorten pipeline
    while (pipeline.length > 0) {
        if (pipeline[0].status === Message.FINISHED) {
            pipeline.shift();
        } else break;
    }

};

window.socket = socket;


export default {
    socket, queue
}

