<template>
    <div><pre>{{ state }}</pre></div>
</template>

<script>
import {state} from '@/engine/state'

export default {
    name: 'ScreenCockpit',
    data() {
        return {
            state,
        }
    },
}
</script>

<style scoped>
    div {
        width: 100%;
        text-align: left;
        overflow: scroll;
    }

</style>


