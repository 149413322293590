/**
 * Connect to externally loaded screens
 */

import { bam } from '@/engine/init'
import {state, updateFramesState, updateState} from '@/engine/state'

bam.frames =  {
    header: {},
    game: {},
    footer: {},
}

bam.registerVue = (frame, vue) => {
    bam.frames[frame].vue = vue;
    updateFramesState(state.game.variables);
    console.log("reigster frame vue");
};

bam.afterFrameVueMounted = (frame) => {
    console.log("got that...moutned", frame);
}

bam.initFrame = (o) => {
    const frameName = o.getAttribute("x-frame");
    console.log("init frame", o, frameName, " dimmed=", state.dimmed);
    // window.bam.updateState({'dimmed': false})
    updateState({'dimmed': false});

    document.getElementById("objectScreenGame").contentWindow.document
        .querySelectorAll("[data-hotlink]")
        .forEach((node) => {
            const role = node.getAttribute("data-hotlink");
           console.log("hotlink: ", node, role);
           const url = window.location.origin + "/?hotlink=" + state.game.session;
           node.innerHTML = "hotlink: " + url;
        });
    // const theObject = document.getElementById('gameDisplayContainer');
    // if (theObject) {
    //     // theObject.classList.toggle('dimmed', true);
    //     // theObject.classList.toggle('not-dimmed', false);
    //     theObject.classList.toggle('dimmed', false);
    //     theObject.classList.toggle('not-dimmed', true);
    // }
}

