<template>
    <div id="gameDisplayContainer" :class="{'dimmed':state.dimmed, 'not-dimmed':!state.dimmed}" >
        <DefaultGameDisplay v-if="!htmlUrl"/>
        <object v-if="htmlUrl" id="objectScreenGame" type="text/html" :data="htmlUrl" x-frame="game"
                onload="window.bam.initFrame(this);"
                @loadstart="dimFrame"
        ></object>
    </div>
</template>

<script>
import {state, updateState} from '@/engine/state.js'
import DefaultGameDisplay from '@/components/game/DefaultGameDisplay'

let htmlUrlOld = null;


export default {
    name: 'GameDisplay',
    components: {
        DefaultGameDisplay,
    },
    data() {
        return {
            state
        }
    },
    computed: {
        // a computed getter
        htmlUrl() {
            // `this` points to the component instance
            // return 'test/test.html';
            const htmlUrlNew =  state.chapter && state.chapter.html  ? state.chapter.html : null;
            if (htmlUrlNew !== htmlUrlOld) {
                htmlUrlOld = htmlUrlNew;
                console.log("dim!");
                updateState({'dimmed': true});
                const theObject = document.getElementById('gameDisplayContainer');
                    if (theObject) {
                        // theObject.classList.toggle('dimmed', true);
                        // theObject.classList.toggle('not-dimmed', false);
                        // theObject.classList.toggle('dimmed', false);
                        // theObject.classList.toggle('not-dimmed', true);

                    }
            }
            console.log("HTML url: ", htmlUrlNew);
            return htmlUrlNew;
        }
    },
    methods: {
        dimFrame(o) {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!! dim frame", o);
        },
    }
}

</script>

<style scoped>
div#gameDisplayContainer {
    width: 100%;
    flex: 1;
    background-color: black;

}

object {
    width: 100%;
    height: 100%;
}



</style>