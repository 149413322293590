<template>
    <div class="cockpitDisplay">
        <h1>Welcome to the cockpit..</h1>
        <canvas id="cockpitCanvas"></canvas><br />
        <h3 v-if="!state.sound.playing" id="cockpitPaused">not playing audio</h3>
        <h3 v-if="state.sound.playing && state.sound.synced" id="cockpitSynchronized">SYNCHRONIZED</h3>
        <h3 v-if="state.sound.playing && !state.sound.synced" id="cockpitSynchronizing">..synchronizing..</h3>
        <p>red: how many milliseconds out of sync</p>
        <p>blue: playback rate</p>
        <button onclick="window.mediaTest();"><b>START AUDIO TEST</b></button>
        <button @click="debugSync()"><b>Draw Sync Analysis</b></button>
        <br />
        <button onclick="window.snd.setSyncMode('soft')">switch to soft sync</button>
        <button onclick="window.snd.setSyncMode('hard')">switch to hard sync</button>
        <button onclick="window.snd.setSyncMode('apple')">switch to apple sync</button>
        <br />
        <button onclick="window.snd.seekError(Math.random() - 0.5)">put audio out of sync</button>
        <button onclick="window.snd.mediaProvider.setTime(-3)">Media time -3</button>
        <button onclick="window.snd.mediaProvider.setTime(window.snd.htmlElement.duration - 3)">Media time end-3</button>
        <button onclick="window.snd.mediaProvider.setTime(window.snd.htmlElement.duration + 3)">Media time end+3</button>
        <button onclick="window.snd.mediaProvider.setTime(window.snd.htmlElement.duration * Math.random())">Media time random</button>
         <button onclick="window.snd.mediaProvider.setLength(5)">Short loop</button>
        <br />
        <button onclick="window.snd.rate(1.1)">rate=1.1</button>
        <button onclick="window.snd.rate(0.9)">rate=0.9</button>
        <div style="text-align:left;margin-top:50px;">
            <pre>mode: {{ state.sound.syncMode }}</pre>
        <pre>html time: {{ state.sound.htmlTime }}</pre>
        <pre>sound diff: {{ state.sound.diff }}</pre>
        <pre>sound rate: {{ state.sound.rate }}</pre>
        <pre>rate change error: {{ state.sound.rateChangeError }}</pre>
        </div>
    </div>
</template>

<script>

import {state, updateSubState} from '@/engine/state.js'
let debugSyncInterval = null;
// let debugSyncActivated = false;

export default {
    name: 'CockpitDisplay',
    data() {
            return {
                state,
            }
        },
    methods: {
        debugSync() {
            // const canvas = document.getElementById("cockpitCanvas");
            // const canvas2d = canvas.getContext("2d");
            let x = 0;
            // canvas2d.fillStyle = "black";
            // canvas2d.fillRect(0, 0, canvas.clientWidth - 1, canvas.clientHeight - 1);
            const h = 150; // canvas.clientHeight;
            const w = 300; // canvas.clientWidth;
            const yMid = Math.floor(h  * 0.5);
            if (debugSyncInterval) clearInterval(debugSyncInterval);
            debugSyncInterval = setInterval(() => {
                const canvas = document.getElementById("cockpitCanvas");
                if(!canvas) return;
                const canvas2d = canvas.getContext("2d");
                canvas2d.fillStyle = 'black';
                canvas2d.fillRect(x, 0, 1, h);
                canvas2d.fillStyle = 'green';
                canvas2d.fillRect((x + 1) % w, 0, 1, h);
                canvas2d.fillStyle = 'green';
                canvas2d.fillRect((x) % w, yMid, 1, 1);


                updateSubState('sound', {
                    syncMode: window.snd.syncMode,
                    diff: window.snd.diff(),
                    rate: window.snd.getRate(),
                    htmlTime: window.snd.htmlElement.currentTime,
                    rateChangeError: window.snd.syncAppleStats.rateChangeError,
                });

                let v, y;

                canvas2d.fillStyle = 'blue';
                v = (window.snd.htmlElement.playbackRate - 1) * 5;
                y = Math.max(0, Math.min(h - 1, Math.round(yMid + v * yMid)));
                canvas2d.fillRect(x, y, 1, 1);

                                canvas2d.fillStyle = 'red';
                v = window.snd.diff();
                y = Math.max(0, Math.min(h - 1, Math.round(yMid + v * yMid)));
                canvas2d.fillRect(x, y, 1, 1);



                x = (x + 1) % w;
                // console.log("x is now", x, " width:", canvas.clientWidth);
            }, 50);
        }

    }
}


</script>

<style scoped>
div.cockpitDisplay {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
}

div.cockpitDisplay button {
    margin: 5px;
}

canvas#cockpitCanvas {
    width: 500px;
    height: 500px;
    border: 1px solid white;
}

h3#cockpitPaused {
    color: grey;
}
h3#cockpitSynchronized {
    color: green;
}
h3#cockpitSynchronizing {
    color: red;
}
</style>