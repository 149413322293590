<template>
    <div class="footer">
        <div class="material-icons playing" v-if="state.sound.playing && state.sound.synced">headset</div>
        <div class="material-icons syncing" v-if="state.sound.playing && !state.sound.synced">headset</div>
        <div class="material-icons" v-if="!state.sound.playing">headset_off</div>
        <div>{{ gameTime }}</div>
    </div>
</template>

<script>

// , playing: state.sound.playing && state.sound.synced, syncing: state.sound.playing && !state.sound.synced
import {state} from '@/engine/state.js'

export default {
    name: 'StatusFooter',
    components: {},
    data() {
        return {
            state
        }
    },
    computed: {
        // a computed getter
        gameTime() {
            // `this` points to the component instance
            return state.time.formatted.game;
        }
    }
}
</script>

<style>
div.footer {
    height: 40px;
    font-size: 1.8rem;
    background-color: #006e6e;
    display:flex;
    align-items: center;
    justify-content: center;
}

div.footer .material-icons {
    font-size: 30px;
    margin-right:10px;
    /*color: #4f4f4f;*/
}

div.footer .playing {
}

div.footer .syncing {
    animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
</style>