<template>
    <div class="screenWelcome flex-column align-items-center justify-content-center">
        <h1>Welcome to Bamory!</h1>
        <p>You're player name is..</p>
        <h2>"{{ state.user.name }}"</h2>
        <img class="avatar" :src="state.user.avatar" />
        <div v-if="state.game && !state.hotlink" class="card" >
            <div class="card-body d-grid gap-2">
                Continue to play
                <h3 class="card-title">"{{ state.game.title }}"</h3>
                <p v-if="state.game">Session <b class="text-uppercase">{{ state.game.session }}</b></p>
                <button class="btn btn-primary btn-block" @click="continueGame()">Continue</button>
            </div>
        </div>
        <div class="card">
            <div class="card-body d-grid gap-2">
                    <h3 v-if="!state.hotlink">Enter <span v-if="state.game">new </span>game code:</h3>
                    <input v-if="!state.hotlink" :value="inputGameCode" @input="event => inputGameCode = event.target.value"
                           @keydown="() => inputPasswordHide=true"
                           @keyup.enter="enterGameCode()"
                           type="text" class="form-control text-uppercase " placeholder="Enter new game code" />
                    <input v-if="!inputPasswordHide" :value="inputPassword" @input="event => inputPassword = event.target.value"
                           type="password" class="form-control" placeholder="Enter game password" />
                    <button class="btn btn-primary btn-block" type="button" @click="enterGameCode()" >
                        <span v-if="state.hotlink">Join Game</span><span v-if="!state.hotlink">Launch</span>
                    </button>
                    <div v-if="alertWrongPassword" class="alert alert-danger">Wrong password!</div>
                    <div v-if="alertNoPassword" class="alert alert-danger">Password required!</div>
                    <div v-if="alertErrorLoadingGame" class="alert alert-danger">{{ alertErrorLoadingGame }}</div>
            </div>
        </div>
        <p class="credits">Version {{ state.version }} - created and hosted by <a href="https://omnitopos.net/">omnitopos.net</a></p>
    </div>

</template>

<script>
    import {state, updateState} from '@/engine/state.js'
    import { Message } from '@/engine/message'

    export default {
        name: 'ScreenWelcome',
        components: {},
        data() {
            return {
                state,
                inputGameCode: state.hotlink,
                inputPassword: 'test',
                inputPasswordHide: true,
                alertWrongPassword: false,
                alertNoPassword: false,
                alertErrorLoadingGame: null,
                welcomeMessage: state.user.name ? state.user.name : "new",
            }
        },
        methods: {
            enterGameCode() {
                console.log("enterGameCode()");
                const gamecode = this.inputGameCode.toLowerCase().trim();
                const password = this.inputPassword.toLowerCase().trim();
                if (!this.inputPasswordHide && !password) {
                    this.alertWrongPassword = false;
                    this.alertNoPassword = true;
                    return;
                }
                const message = new Message('join', 'game', {'gamecode': gamecode, 'password': password});
                message.callback((res) => handleGameCodeResponse(this, res))
                message.send();
                window.snd.enable();
            },
            continueGame() {
                const message = new Message('join', 'game', {'gamecode': state.game.session, 'password': null});
                message.callback((res) => handleGameCodeResponse(this, res))
                message.send();
                window.snd.enable();
            }
        }
    }

    const handleGameCodeResponse = (localState, res) => {
        if(res.error) {
            localState.alertErrorLoadingGame = res.error;
        } else {
            localState.alertErrorLoadingGame = null;
        }
        if(res.password === 'required') {
            localState.inputPasswordHide = false;
            localState.inputPassword = '';
            localState.alertWrongPassword = false;
            localState.alertNoPassword = false;
        } else if (res.password === 'wrong') {
            localState.inputPassword = '';
            localState.alertWrongPassword = true;
            localState.alertNoPassword = false;
        } else if (res.cockpit === 'activated') {
            state.cockpit = true;
            // state.screen = 'cockpit';
        } else if (res.game) {
            console.log("handleGameCodeResponse: type=" + typeof(res.game));
            updateState({'hotlink':'', 'game':res.game, 'chapter': res.chapter, 'dimmed': true});
            state.screen = 'game';
            window.scrollTo(0,0);
            setTimeout(() => { window.scrollTo(0,0); }, 1000);
        }
    }

</script>

<style scoped>
    div.screenWelcome {
        width: 100%;
        /*flex: 1;*/
        overflow-y: scroll;
    }

    div.card  {
       width: 80%;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }

    img.avatar {
        width: 80%;
        max-width: 350px;
        max-height: 100px;
    }

    p.credits {
        color: grey;
    }
    p.credits a, p.credits a:hover, p.credits a:visited {
        color: grey;
    }
</style>


