/**
 *  Logging system
 *
 */

import {reactive} from "vue";
import moment from "moment";

export const logHistory = reactive({
    messages:[]}
);

const addToLogHistory = (module, level, text) => {
    logHistory.messages.push({
        time: moment().format('HH:MM:SSS'),
        module: module,
        level: 'INFO',
        text: text,
    });
}

const info = (module, text) => {
    console.log((module ? module + ': ' : '') + text);
    addToLogHistory(module, 'INFO', text);
}

const verbose = (module, text) => {
    // console.log((module ? module + ': ' : '') + text);
    addToLogHistory(module, 'VERBOSE', text);
}

const debug = (module, text) => {
    // console.log((module ? module + ': ' : '') + text);
    addToLogHistory(module, 'DEBUG', text);
}

const error = (module, text) => {
    console.error((module ? module + ': ' : '') + text);
    addToLogHistory(module, 'ERROR', text);

}

export default { info, verbose, debug, error, logHistory }