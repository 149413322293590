<script setup>
import { ref, onMounted } from 'vue'
import snd from '@/engine/snd';

const audioEnabler = ref()

onMounted(() => {
  window.snd = new snd.SndChannel(audioEnabler.value, 'hard');
})

// const audioError = (o) => {
//     console.error("audio error, cannot load!", o);
// }
</script>

<template>
    <div id="soundEngine" class="hidden">
        <audio ref="audioEnabler" id="audioEnabler" controls loop src="/snd/soundcheck.mp3"/>
    </div>
</template>

<script>

</script>

<style scoped>
    div#soundEngine.hidden {
        display:none;
    }
</style>