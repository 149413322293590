import log from "@/engine/log"
import time from "@/engine/time"
import { state, updateState} from "@/engine/state"

export const cfg = {
    'refreshInterval': 50,
    'syncMode': 'soft',
    'syncModeThreshold': 0.03, // if rate change takes longer than this (seconds) then switch to hard sync
    'softSyncInterval': 1000,
    'hardSyncInterval': 30000,
    'rateChangeTimes': 10, // how many times to measure rate change before deciding if to switch to hard sync
};




class MediaProviderSoundCheck {
    constructor(htmlElement) {
        this.channels = [];
        this.playing = true;
        this.url = htmlElement.src;
        this.length = 10;
        this.soundCheck = true;
        this.randomDelta = 10;
        this.randomizeTime();
    }
    registerChannel() {
    }
    getTime() {
        return (time.server() + this.randomDelta) % this.length;
    }
    randomizeTime() {
        this.randomDelta += Math.random() * 2.0 - 1.0;
    }
    getUrl() { return this.url; }
    getPlaying() { return this.playing; }
    setPlaying(playing) { this.playing = playing; }
}

// sound engine for "Snd" module - type: html5
export class SndChannel {

    constructor(htmlElement, syncMode) {
        if (!htmlElement) throw new Error("no html audio element passed");
        log.debug('Snd', 'new(syncMode=' + syncMode + ')');
        this.htmlElement = htmlElement
        this.htmlElement.engine = this;
        this.seekToken = 0; // incremented when engine calls seek, decremented after seek.. needed to detect user seeks
        this.seekOffset = 0;
        this.resetSyncStats();
        this.synced = false;
        this.playing = false;
        this.enabled = false;
        this.setMediaProvider(null)
        this.mediaProviderSoundCheck = new MediaProviderSoundCheck(htmlElement);
        const channel = this;
        setInterval(function () {
            channel.cronJob();
        }, cfg.refreshInterval);
    }

    getMediaProvider() { return this.mediaProvider; }

    setMediaProvider(mediaProvider) {
        this.mediaProvider = mediaProvider;
        if(mediaProvider) mediaProvider.registerChannel(this);
        this.onmediaupdate();
    }

    onmediaupdate() {
        if(this.mediaProvider === null) {
            this.load(null)
            return;
        }
        const url = this.mediaProvider.getUrl();
        if (this.htmlElement.src !== url) this.load(url);
    }

    cronJob() {
        // we can't train the sound engine while no sound is loaded - pretend as if training is completed meanwhile
        if (!this.htmlElement || isNaN(this.htmlElement.duration)) {
            if (state.soundCheck < 1) updateState({"soundCheck": 1});
            return;
        }
        //
        let mediaTime = this.mediaProvider ? this.mediaProvider.getTime() : null;
        if(mediaTime < 0) mediaTime = null;
        if(mediaTime > this.htmlElement.duration) mediaTime = null;
        if(!mediaTime || !this.mediaProvider.getPlaying()) {
            if(this.htmlElement && !this.htmlElement.paused) {
                console.log("snd: no media provider -> pause");
                this.htmlElement.pause();
            }
            this.playing = false;
            return;
        }



        if (this.htmlElement.paused) {
            log.info("Snd", "paused!");
            if (this.htmlElement.duration > 0 && this.htmlElement.duration < Number.POSITIVE_INFINITY) {
                log.info("Snd", `is paused -> set to play. duration=${this.htmlElement.duration}`);
                this.htmlElement.play()
                    .then(() => {
                        this.syncOnce();
                        this.playing = true;
                    })
                    .catch(function() {
                      log.info("Snd", ".play() rejected");
                    });
            } else log.info("Snd", "Media not playable, length=", this.htmlElement.duration);
            return;
        }

        if (state.soundCheck < 1 || this.mediaProvider.soundCheck) {
            if(!this.htmlElement.muted) this.htmlElement.muted = 1;
            if(!this.htmlElement.volume === 0) this.htmlElement.volume = 0;
        } else {
            if (this.htmlElement.muted) {
                this.htmlElement.muted = false;
                log.info("Snd", "unmute");
            }
            if (this.htmlElement.volume < 1) {
                this.htmlElement.volume = Math.min(1, this.htmlElement.volume + 1 / cfg.refreshInterval);
            }
        }
        this.playing = true;
        this.sync();
    }

    diff() {
        if (!this.htmlElement || !this.mediaProvider || !this.htmlElement.src || !this.htmlElement.duration) return null;
        let s = this.mediaProvider.getTime();
        if(s < 0) s = null;
        if(s > this.htmlElement.duration) s = null;
        return this.htmlElement.currentTime - s;
    }

    mediaReady() {
        return this.htmlElement && this.htmlElement.src;
    }

    syncOnce() {
        log.info("Snd", "syncOnce(). Trace: ", Error().stack);
        this.seek(this.mediaProvider.getTime());
    }
    syncConfig = {
        resyncInterval: 0.5,
        resyncThresholdDiff: 0.2,
        diffHistoryLength: 20,
        seekErrorHistoryLength: 20,
        relativeSeekErrorTolerance: 0.2,
        seekEvaluationDelay: 0.25,
    }

    resetSyncStats() {
        this.synced = false;
        this.syncHardStats = {
            lastSyncTime: 0,
            seekErrorHistory: [],
            diffHistory: [],
            seekErrorOffset: 0,
            syncAttempts: 0,
            seekEvaluated: true,
        }
    }

    sync() {
        const stats = this.syncHardStats;
        const config = this.syncConfig;
        // log.info("SND", "syncHard(), seekEvaluated=" + stats.seekEvaluated);
        const diff = this.diff();
        if (!diff) return;
        const timeSinceLastSync = time.stamp() - stats.lastSyncTime;
        if (!stats.seekEvaluated && config.seekEvaluationDelay <= timeSinceLastSync) {
            // log.info("SND", "evaluate! diff=" + Math.abs(diff));
            stats.seekEvaluated = true;
            if(Math.abs(diff) < 1) {
                stats.diffHistory.push(Math.abs(diff));
                stats.diffHistory.sort();
                stats.seekErrorHistory.push(stats.seekErrorOffset - diff);
                stats.seekErrorHistory.sort();
                const soundCheck = Math.min(1, (stats.diffHistory.length + stats.seekErrorHistory.length)
                    / (this.syncConfig.diffHistoryLength + this.syncConfig.seekErrorHistoryLength) * 2);
                if (state.soundCheck < 1) updateState({soundCheck: soundCheck});
                while(stats.diffHistory.length > this.syncConfig.diffHistoryLength) {
                    stats.diffHistory.shift();
                    stats.diffHistory.pop();
                }
                while(stats.seekErrorHistory.length > this.syncConfig.seekErrorHistoryLength) {
                    stats.seekErrorHistory.shift();
                    stats.seekErrorHistory.pop();
                }
                // use median error offset for next sync
                if (stats.seekErrorHistory.length > 4)
                    stats.seekErrorOffset = stats.seekErrorHistory[Math.floor(stats.seekErrorHistory.length / 2)];
            } // else console.log("big diff!", diff);
            return;
        } else if (!stats.seekEvaluated) {
            // no further actions until last seek is evaluated
            // console.log("wait", config.seekEvaluationDelay - timeSinceLastSync);
            return;
        }
        if (timeSinceLastSync <= this.syncConfig.resyncInterval) return;
        // if current diff is better than median diff after sync: no use syncing!

        const medianDiff = stats.diffHistory[Math.floor(stats.diffHistory.length / 4)];
        // console.log("diff is " + (Math.abs(diff) / (medianDiff)) + " x median");
        if (state.soundCheck === 1 && stats.diffHistory.length > 8) {
            if(Math.abs(diff) < medianDiff * 4) {
                this.synced = true;
                return;
            }
        }
        // else if (this.synced && Math.abs(diff) < this.syncHardConfig.resyncThresholdDiff) return;
        if (state.soundCheck < 1) { this.mediaProviderSoundCheck.randomizeTime(); }
        this.synced = false;
        this.seek(this.getMediaTime() + stats.seekErrorOffset);
        this.syncHardStats.seekEvaluated = false;
        this.syncHardStats.lastSyncTime = time.stamp();
        // this.synced = true;
    }

    seek(s) {
        // console.log("seeK: ", s);
        const len = (this.len());
        if (len > 0) {
            // try to target better from experience
            const diff = this.diff();
            if (diff < 1) this.seekOffset = Math.max(-1, Math.min(1, this.seekOffset + diff));
            s = s - this.seekOffset;

            s = s % len;
            if (s < 0) s += len;
            // log.info('Snd', 'seek: ' + s);
            this.seekToken++;
            // after a seek, sync relevant variables need a reset
            // this.resetSoftSyncStats();
            this.diffHistory = [];
            // do the seek
            this.htmlElement.currentTime = s;
        } else log.error('Snd', 'cant seek cause no len()');
    }

    seekError(e) { this.seek(this.htmlElement.currentTime + e);
    }

    getMediaTime() {
        return this.mediaProvider.getTime();
        // if (state.soundCheck === 1) return this.mediaProvider.getTime()
        // else return this.mediaProviderSoundCheck.getTime();
    }

    load(url) {
        console.log("load audio:", url);
        if(!url) url = 'snd/soundcheck.mp3';
        if(!this.htmlElement) throw Error("Can't load url without html element");
        log.verbose('Snd', 'load audio: ' + url);
        this.htmlElement.src = url;
    }

    len() {
        if (isNaN(this.htmlElement.duration)) return 0;
        return this.htmlElement.duration;
    }

    enable() {
        console.log("snd: enable()")
        this.htmlElement.currentTime = 0;
        this.htmlElement.volume = 0;
        this.htmlElement.play().catch(() => {
            console.log("snd: caught aborted play command");
        });
        const htmlElement = this.htmlElement;
        const mp = new MediaProviderSoundCheck(htmlElement);
        this.setMediaProvider(mp);
        this.htmlElement.onplaying = function () {
            htmlElement.onplaying = null;
            htmlElement.engine.enabled = true;
            console.log('enabler playing! load real audio now possible..');
            // console.log('TODO: load chapter audio');
            // Snd.load(Game.chapter.url);
        };
    }

}

export default {
    SndChannel
}

