<template>
    <div class="header d-flex justify-content-center">
<!--        <span class="material-icons">arrow_back</span>-->
        <div style="margin-right:5px;"><p><b>Player:</b>{{ player.name }}</p> </div>
        <div v-if="game">
            <b>Game code: </b> {{ String(game.session).toUpperCase() }}
        </div>
    </div>
</template>

<script>
    import {state} from '@/engine/state.js'

    export default {
        name: 'StatusHeader',
        components: {},
        data() {
            return {
                'game': state.game,
                'player': state.user,
            }
        }
    }
</script>

<style scoped>
    div.header {
        height: 3em;
        background-color: #006e6e;
    }

    div.header > span.material-icons {
        font-size: 50px;
    }
</style>