/**
 * Global state
 */
import { bam } from '@/engine/init.js'
import { reactive } from 'vue'
import Cookies from 'js-cookie'
import time from '@/engine/time.js'
import { updateChapter } from '@/engine/game.js'

export const updateState = (update) => {
    for (let o in update) {
        state[o] = update[o];
    }
    if (state.game == null && state.screen === 'game') {
        console.log("lost server - reset client");
        state.screen = 'welcome';
        state.playing = false;
    }
    if (update.game && update.game.variables) {
        updateFramesState(update.game.variables)
    }
    if (update.chapter) updateChapter(update.chapter);
    if (update.frame) {
        console.error("depreceated? why this? ");
        throw new Error("depreceated?");
        // updateFramesState(update.frame);
    }
}

export const updateSubState = (dir, update) => {
    for (let o in update) {
        state[dir][o] = update[o];
    }
    if (dir === 'frame') updateFramesState(update);
}

export const updateFramesState = (update) => {
    Object.values(bam.frames).forEach((frame) => {
        if(!frame || !frame.vue) return;
        for (let o in update) {
            frame.vue[o] = update[o];
        }
    });
}

export const setScreen = (screen) => {
    updateState({'screen': screen});
}

const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
}

const getUrlParameter = (param) => { return new URL(document.location.href).searchParams.get(param); }

const getUidAndUpdateCookie = () => {
    let uid = getUrlParameter("uid");
    if (uid === 'new') {
        Cookies.set("uid", "");
        window.location = "?";
        return;
    }
    if (!uid) uid = Cookies.get("uid");
    if (!uid) {
        uid = 'u-' + makeId(10);
        Cookies.set("uid", uid);
    }
    return uid;
}

bam.defaultState = {
    version: '4.0.1',
    screen: 'welcome',
    cockpit: getUrlParameter("console") != null,
    hotlink: getUrlParameter("hotlink"),

    com: {
        connected: false,
    },
    client: {
        id: 'c-' + makeId(10),
    },
    user: {
        uid: getUidAndUpdateCookie(),
        name: null,
    },
    game: {
        id: null,
        title: null,
        session: null,
        playersMissing: null,
        master: null,
    },
    chapter: null,
    playing: false,
    players: {},
    soundCheck: 0,
    sound: {
        syncMode: null,
        playing: false,
        synced: false,
        rate: 1,
        diff: 0,
        htmlTime: 0,
        rateChangeError: 0,
    },
    time: {
        server: Date.now(),
        game: 0,
        diff: 0,
        formatted: {
            game: 0,
        }
    },

    frame: {
        title: 'TITLE TEST',
        test: 'test',
        a: 5,
        b: 42,
        time: {
            game: 0,
            formatted: {
                game: '',
            }
        }
    },

    frameHeaderUrl: null,
    // frameGameUrl: 'test/test.html',
    frameGameUrl: null,
    frameFooterUrl: null,

    dimmed: false,

    showConsole: getUrlParameter("console") != null,
};

// remove hotlink param from url
history.replaceState && history.replaceState(
  null, '', location.pathname + location.search.replace(/[?&]hotlink=[^&]+/, '').replace(/^&/, '?') + location.hash
);

export const state = reactive(bam.defaultState);
console.log("state init: ", state);

bam.state = state;
bam.updateState = updateState;
bam.updateSubState = updateSubState;
bam.updateFramesState = updateFramesState;

setInterval(function(){
    const timeServer = time.server();
    const timeGame = state.game && state.game.timeStart ? timeServer - state.game.timeStart : 0;
    const timeChapter = bam.chapter.getTime();
    const timeUpdate = {
        server: timeServer,
        game: timeGame,
        chapter: timeChapter,
        diff: time.diff(),
        formatted:  {
            game: time.format(Math.max(0, timeGame)),
            chapter: time.format(Math.max(0, timeChapter)),
        }
    }
    const soundUpdate = {
        synced: window.snd.synced,
        playing: window.snd.playing,
    }
    updateState({time: timeUpdate, sound: soundUpdate});
    updateFramesState({time: timeUpdate})
}, 300);