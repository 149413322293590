<template>
    <div class="container d-flex align-items-center justify-content-center">
        <div>
            <h2>Initializing..</h2>
            <progress v-bind:value="state.soundCheck" max="1" />
        </div>
    </div>
</template>

<script>
import {state} from '@/engine/state.js'

export default {
    name: 'SoundCheck',
    components: {
    },
    data() {
        return {
            state
        }
    },
}

</script>

<style scoped>
    div.container {
        height: 100vh;
    }
</style>