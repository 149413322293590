<template>
    <div class="container d-flex align-items-center justify-content-center">
        <h1>Connecting to server..</h1>
    </div>
</template>

<script>
export default {
  name: 'ScreenConnectingToServer',
  props: {
  }
}
</script>

<style scoped>
    div.container {
        height: 100vh;
    }
</style>