<template>
    <div class="screenGameOver">
        welcome
    </div>
</template>

<script>
    import {state} from '@/engine/state.js'

    export default {
        name: 'ScreenGameOver',
        components: {

        },
        data() {
            return {state}
        },
    }
</script>

<style>

</style>


