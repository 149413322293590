<template>
    <SoundCheck v-if="state.soundCheck < 1" />
    <StatusHeader v-if="state.soundCheck === 1" />
    <GameDisplay v-if="state.soundCheck === 1" />
    <StatusFooter v-if="state.soundCheck === 1"/>
</template>

<script>
import StatusHeader from '@/components/status/StatusHeader.vue'
import GameDisplay from '@/components/game/GameDisplay.vue'
import StatusFooter from '@/components/status/StatusFooter.vue'
import SoundCheck from '@/components/game/SoundCheck.vue'

import {state} from '@/engine/state.js'

export default {
    name: 'ScreenGame',
    components: {
        StatusHeader,
        GameDisplay,
        StatusFooter,
        SoundCheck,
    },
    data() {
        return {state}
    },
}
</script>

<style scoped>

</style>


