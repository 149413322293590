/**
 * A message between server and client
 */

import com from "@/engine/com"
import time from "@/engine/time";
import log from "@/engine/log";
import {state, updateState } from "@/engine/state";

const assert = require('assert');

export class Message {

    static count = 0;

    // message status
    static SEND_INSTANTLY = 10; // always send right away
    static SEND = 11; // send when on top of queue
    static SEND_AND_WAIT = 12; // send when on top of queue and block queue until reply arrived
    static WAIT = 20; // awaiting response
    static WAIT_AND_HALT = 21; // awaiting response & halt queue
    static RECEIVED = 30; // this is a received message, doesn't affect pipeline
    static FINISHED = 40; // message has been fully processed - nothing else to do

    constructor(
        method,
        subject,
        params = null,
        status = Message.SEND,
        recipient = 'srv',
        sender = state.user.uid,
    ) {
        this.status = status;
        if (status !== Message.RECEIVED) {
            this.cid = com.socket.id;
            this.mid = state.client.id + '-' + Message.count++;
            this.clientId = state.client.id;
            this.stamps = [];
            this.stamp();
            this.res = null;
        }
        //
        this.sender = sender;
        this.recipient = recipient;
        this.method = method;
        this.subject = subject;
        this.params = params;
    }

    static fromReceived(o) {
        assert(o.mid != undefined && o.sender !== undefined && o.recipient !== undefined && o.method !== undefined
            && o.subject !== undefined && o.params !== undefined);
        let m = new Message(o.method, o.subject, o.params, Message.RECEIVED, o.recipient, o.sender);
        m.mid = o.mid;
        m.cid = com.socket.id;
        if (o.stamps) m.stamps = o.stamps;
        if (o.clientId) m.clientId = o.clientId;
        if (o.res) m.res = o.res;
        log.verbose('MSG','parsed received: ', m);
        return m;
    }

    callback(f) {
        this.callback = f;
        return this;
    }

    send() {
        com.queue(this);
        log.verbose('MSG',"send(): " + this);
    }

    stamp() {
        this.stamps.push(time.stamp());
    }

    json() {
        const json = {
            'mid': this.mid,
            'clientId': this.clientId,
            'role': null, /* TODO: activate: user.role, */
            'sender': this.sender,
            'recipient': this.recipient,
            'method': this.method,
            'subject': this.subject,
            'params': this.params,
            'res': this.res,
            'stamps': this.stamps
        };
        for (let i in json) if (json[i] === undefined) json[i] = null;
        return json;
    }

    parseResponse() {
        log.verbose('MSG', "parseResponse(): " + this, "type: " + typeof(this.res), this.res);
        if (!this.callback)
            throw new Error("Received response to message with no callback defined");
        this.callback(this.res);
    }

    parseIncoming() {
        log.verbose('MSG', "parseIncoming(): " + this, this);
        const command = this.method + "_" + this.subject;
        if (!commands[command]) throw Error("Received unknown command: " + command);
        commands[command](this.params, this);
    }

    toString() {
        return "[MSG #" + this.mid + " via con=" + this.cid.substr(0,6)
             + " snd:" + this.sender //(this.sender ? this.sender.substr(0,4) : "null")
            + " rcp:" + this.recipient
            + " cmd:" + this.method + "-" + this.subject
            + (this.params ? " p:" + this.params : "")
            + "]";
    }
}

const commands = {
    'push_state': (params) => {
        log.verbose('MSG',"state: " + JSON.stringify(params));
        updateState(params)
    },
    'push_error': (params) => {
        alert('ERROR: ' + params);
    },
}

export default {
    Message
}