import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@/assets/main.css'

import '@/engine/init';
import '@/engine/snd';
import '@/engine/game';
import '@/engine/frames';
import '@/engine/com';

import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')

