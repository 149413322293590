<template>
    <StatusHeader/>
    <CockpitDisplay />
    <StatusFooter/>
</template>

<script>
    import StatusHeader from '@/components/status/StatusHeader.vue'
    import StatusFooter from '@/components/status/StatusFooter.vue'
    import CockpitDisplay from '@/components/cockpit/CockpitDisplay'

    export default {
        name: 'ScreenCockpit',
        components: {
            StatusHeader,
            StatusFooter,
            CockpitDisplay,
        },
    }
</script>

<style>

</style>


