<template>
    <div class="mainLayout">
        <SoundEngine />
        <CockpitHeader v-if="state.cockpit" />
        <ScreenConnectingToServer v-if="!state.com.connected"/>

        <ScreenWelcome v-if="state.com.connected && state.screen === 'welcome'"/>
        <ScreenState v-if="state.screen === 'state'"/>
        <ScreenGameWaiting
            v-if="state.com.connected && state.screen === 'game' && state.game && state.game.phase === 'waiting'"/>
        <ScreenGame
            v-if="state.com.connected && state.screen === 'game' && state.game && state.game.phase === 'running'"/>
        <ScreenGameFinished
            v-if="state.com.connected && state.screen === 'game' && state.game && state.game.phase === 'finished'"/>
        <div v-if="state.com.connected && state.screen == 'game' && !state.game">
            Please reload this page
        </div>
        <ScreenCockpit v-if="state.com.connected && state.screen === 'cockpit'"/>
        <ConsoleDisplay v-if="state.showConsole"/>
    </div>
</template>

<script>
import SoundEngine from '@/components/SoundEngine'
import ScreenConnectingToServer from '@/components/status/ScreenConnectingToServer'
import ScreenWelcome from './components/welcome/ScreenWelcome'
import ScreenGameWaiting from './components/game/ScreenGameWaiting'
import ScreenGame from './components/game/ScreenGame'
import ScreenGameFinished from './components/game/ScreenGameFinished'
import ScreenCockpit from './components/cockpit/ScreenCockpit'
import ScreenState from './components/cockpit/ScreenState'
import ConsoleDisplay from './components/cockpit/ConsoleDisplay.vue'
import CockpitHeader from './components/cockpit/CockpitHeader';



import {state} from './engine/state.js'

export default {
    name: 'App',
    components: {
        SoundEngine,
        ScreenConnectingToServer,
        ScreenWelcome,
        ScreenGameWaiting,
        ScreenGame,
        ScreenGameFinished,
        ScreenCockpit,
        ScreenState,
        ConsoleDisplay,
        CockpitHeader,
    },
    data() {
        return {state}
    },
}
</script>

<style>

div.mainLayout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>


