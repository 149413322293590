<template>
    <div id="statusSystem" class="d-flex justify-content-center">
        uid: {{  user  }} cid: {{  client.id  }}<br />
        diff: {{ state.time.diff }}
    </div>
</template>

<script>
    import {state} from '@/engine/state.js'

    export default {
        name: 'StatusSystem',
        components: {},
        data() {
            return {
                'user': state.user.uid,
                'client': state.client,
                state
            }
        }
    }
</script>

<style scope>
    #statusSystem {
        height: 40px;
        color: #9a9a9a;
        background-color: #000000;
    }
</style>