import { Message } from '@/engine/message'
import time from '@/engine/time'

const callEvent = (id, arg1, arg2, arg3, arg4) => {
    console.log("callEvent:", id, arg1, arg2, arg3, arg4);
    const message = new Message('push', 'event', {id, 'args':[arg1, arg2, arg3, arg4]});
    message.send();
}
window.bam.callEvent = callEvent;

window.bam.devShowMediaPlayer = (show = true) => {
    document.getElementById('soundEngine').classList.toggle("hidden", !(show));
}

class Chapter {
    constructor(mediaProvider = null) {
        this.mediaProvider = mediaProvider;
    }

    getTime() {
        return this.mediaProvider ? this.mediaProvider.getTime() : null;
    }
}

window.bam.chapter = new Chapter();

export const updateChapter = (chapter) => {
    // console.log("update chapter!", chapter);
    const snd = window.snd;
    if(!snd.getMediaProvider() || snd.getMediaProvider().soundCheck) {
        snd.setMediaProvider(new MediaProvider());
    }
    const mediaProvider = snd.getMediaProvider();
    // console.log("now set the media url..", mediaProvider);
    mediaProvider.setUrl(chapter.audio);
    mediaProvider.setTimeStart(chapter.timeStart);
    mediaProvider.setTimeOffset(chapter.timeOffset);
    mediaProvider.setLength(chapter.length);
    mediaProvider.setLoop(chapter.loop);
    mediaProvider.setPlaying(true);
    window.bam.chapter = new Chapter(mediaProvider);
}


export class MediaProvider {

    constructor() {
        this.channels = [];
        this.playing = false;
        this.length = 0;
        this.timeOffset = 0;
        this.soundCheck = false;
    }

    setTimeStart(timeStart) {
        if(timeStart === this.timeStart) return;
        this.timeStart = timeStart;
        // this.emitUpdateEvent();
    }

    setTimeOffset(timeOffset) {
        this.timeOffset = timeOffset;
        // this.emitUpdateEvent();
    }

    setTime(t) {
        this.timeStart = time.server() - t;
    }

    getTime() {
        const t = time.server() - this.timeStart + this.timeOffset;
        if (this.loop) return t % this.length;
        return t;
    }

    getLength() {
        throw new Error("this method must be implemented by a subclass");
    }

    setLength(length) {
        if(this.length === length) return;
        this.length = length;
        // this.emitUpdateEvent();
    }

    setLoop(loop) {
        if(this.loop === loop) return;
        this.loop = loop;
        // this.emitUpdateEvent();
    }

    getPlaying() {
        return this.playing;
    }

    setPlaying(playing) {
        if(this.playing === playing) return;
        this.playing = playing;
        // this.emitUpdateEvent()
    }

    getUrl() {
        return this.url;
    }

    setUrl(url) {
        if(url === this.url) return;
        this.url = url;
        this.emitUpdateEvent();
    }

    registerChannel(channel) {
        if (this.channels.includes(channel)) return;
        this.channels.push(channel);
    }

    unregisterChannel(channel) {
        this.channels = this.channels.filter(item => item !== channel)
    }

    emitUpdateEvent() {
        this.channels.forEach((channel) => channel.onmediaupdate())
    }
}

window.MediaProvider = MediaProvider;
window.mediaTest = () => {
    const m = new MediaProvider();
    m.setUrl("test/game1.mp3");
    m.setTimeStart(time.server() + 3);
    window.snd.setMediaProvider(m);
    m.setLength(1000);
    m.setPlaying(true);
    window.m = m;
}
