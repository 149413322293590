<template>
    <div id="defaultGameDisplay">
        <div>
            <img src="gfx/headphones.png"/>
            <h3>Listen</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "DefaultGameDisplay"
}
</script>

<style scoped>
div#defaultGameDisplay {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

}

div#defaultGameDisplay img {
    width: 50%;
}
</style>