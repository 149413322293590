<template>
    <div class="consoleDisplay">
        <StatusSystem/>
        <div id="consoleOutput">
            <div  v-for="message in messages" :key="message.time">
                <b>{{ message.time }} {{ message.level }}</b> {{ message.text }}
            </div>
        </div>
        <input id="consoleInput" type="text"/>
    </div>
</template>

<script>
import {logHistory} from "@/engine/log.js"
import StatusSystem from "@/components/status/StatusSystem";

export default {
    name: 'ConsoleDisplay',
    components: {StatusSystem},
    data: () => ({
        messages: logHistory.messages
    }),
}
</script>

<style>
div.consoleDisplay {
    width: 100%;
    display:flex;
    flex: 1;
    flex-direction: column;
    height: 0.5vh;
    background-color: #1f222a;
    color: #838383;
    font-family: 'Consolas', 'Monaco', 'Bitstream Vera Sans Mono', monospace;
    font-size: 10pt;
    text-align: left;
}

div#consoleOutput {
    overflow-y: scroll;

}

input#consoleInput {
    width: 100%;
    position: absolute;
    bottom: 0px;
    margin: 0;
    padding: 5pt;
    box-sizing: border-box;
    border: 0;
    height: 20pt;
    background-color: black;
    color: #9a9a9a;
}

</style>