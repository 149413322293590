<template>
    <div class="header d-flex justify-content-center">
        <button  @click="setScreen('welcome')">Welcome</button>
        <button  @click="setScreen('game')">Game</button>
        <button  @click="setScreen('state')">State</button>
        <button @click="setScreen('cockpit')">Cockpit</button>
        <button onclick="document.getElementById('soundEngine').classList.toggle('hidden')">Player</button>
        Screen: {{ state.screen }}
    </div>
</template>

<script>
    import {state, setScreen} from '@/engine/state.js'

    export default {
        name: 'StatusHeader',
        components: {},
        data() {
            return {
                state,
            }
        },
        methods: {
            setScreen,
        }
    }
</script>

<style scoped>
    div.header {
        height: 50px;
        min-height:50px;
        background-color: #2a2a2a;
    }
</style>